<template>
  <div class="register">
    <div class="middle">
      <div class="con">
        <p class="title">注册</p>
        <!-- <p class="yyqx"></p> -->
        <!-- <p class="step">
          <span class="active">1</span><em></em><span>2</span><em></em><span>3</span>
        </p> -->
        <div class="content">
         
          <el-form :model="ruleForm" style="width: 80%">
             <div class="yy">
              <span class="phonenum">昵称:</span>
              <el-form-item prop="username">
                <el-input
                  type="phone"
                  v-model="ruleForm.username"
                  autocomplete="off"
                  placeholder="请输入您的昵称"
                  v-on:input="secrchphone"
                ></el-input>
              </el-form-item>
            </div>
             <div class="yy">
              <span class="phonenum">手机号:</span>
              <el-form-item prop="phone">
                <el-input
                  type="phone"
                  v-model="ruleForm.phone"
                  autocomplete="off"
                  placeholder="请输入11位手机号码"
                  v-on:input="secrchphone"
                ></el-input>
              </el-form-item>
            </div>
            <div class="yy">
              <span class="phonenum">手机验证码:</span>
              <el-form-item prop="numcode" style="position: relative">
                <el-input
                  v-model="ruleForm.numcode"
                  autocomplete="off"
                  placeholder="请输入手机号验证码"
                ></el-input>
                <button
                  :class="codeactive == 0 ? 'getcode' : 'getcode11'"
                  type="button"
                  @click="getnumcode"
                  :disabled="disabled"
                >
                  {{ this.btnTitle }}
                </button>
              </el-form-item>
            </div>
            <!-- <div class="yy">
              <span class="phonenum" style="display:none">读者证号:</span>

              <el-form-item prop="loginName"  style="display:none">
                <el-input
                  type="phone"
                  v-model="ruleForm.loginName"
                  autocomplete="off"
                  placeholder="请输入您的读者证号"
                  v-on:input="secrchphone"
                ></el-input>
              </el-form-item>
            </div> -->
             <div class="yy">
              <span class="phonenum">姓名:</span>
              <el-form-item prop="lastname">
                <el-input
                  type="phone"
                  v-model="ruleForm.lastname"
                  autocomplete="off"
                  placeholder="请输入您的姓名"
                  v-on:input="secrchphone"
                ></el-input>
              </el-form-item>
            </div>


            <div class="yy">
              <span class="phonenum">身份证号:</span>
              <el-form-item prop="iDCard">
                <el-input
                  type="phone"
                  v-model="ruleForm.iDCard"
                  autocomplete="off"
                  placeholder="请输入您的身份证号码"
                  v-on:input="secrchphone"
                ></el-input>
              </el-form-item>
            </div>
           
            <div class="yy">
              <span class="phonenum">密码:</span>
              <el-form-item prop="password">
                <el-input
                  type="password"
                  v-model="ruleForm.password"
                  autocomplete="off"
                  placeholder="密码长度8-18位必须包含数字和字母"
                  v-on:input="secrchphone"
                ></el-input>
              </el-form-item>
            </div>
            <div class="yy">
              <span class="phonenum">确认密码:</span>
              <el-form-item prop="confirmPwd">
                <el-input
                  type="password"
                  v-model="ruleForm.confirmPwd"
                  autocomplete="off"
                  placeholder="确认密码"
                  v-on:input="secrchphone"
                ></el-input>
              </el-form-item>
            </div>
            <!-- <p class="next" @click="$router.push({ path: '/registernext' })">
            下一步
          </p> -->
          </el-form>
          
        </div>
        <div class="j-center">
          <span class="next" @click="nextseccond">注册</span>
        </div>
        <p class="bottom">
          已有账号，<span @click="$router.push({ path: '/accountlogin' })"
            >立即登录</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../api/api_login";
import Env from "../api/env";
export default {
  name: "register",
  data() {
    return {
      active: 0,
      ruleForm: {
        phone: "",
        // imgcode: "",
        numcode: "",
        value1: "",
        loginName: "",
        username: "",
        password: "",
        iDCard: "",
        confirmPwd: "",
        lastname:""
      },
      isshow: 1,
      btnTitle: "发送验证码",
      disabled: false,
      src: "",
      codeactive: 0,
    };
  },
  props: {},
  methods: {
    secrchphone() {
      if (this.ruleForm.phone.length == 11) {
        this.codeactive = 1;
      }
    },
    dianji() {
      this.isshow = 2;
      this.getimgcode();
    },
    //图片验证吗
    getimgcode() {
      var num = Math.ceil(Math.random() * 10); //生成一个随机数（防止缓存）
      this.src = Env.baseURL + "/api/user/getCaptcha?" + num;
    },
    //手机短信
    getRegSmsCode() {
      // RegSmsCode
      API.zcSmscode({
        telephone: this.ruleForm.phone,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: "短信已发送，请注意查收",
            type: "success",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getnumcode() {
      //倒计时
      if (!/^1[3456789]\d{9}$/.test(this.ruleForm.phone)) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号",
          type: "error",
        });
      } else {
        this.validateBtn();
        this.getRegSmsCode();
      }
    },
    validateBtn() {
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    nextseccond() {
      if (this.ruleForm.phone == "") {
        this.$message.error("请输入手机号");
      } else if (this.ruleForm.numcode == "") {
        this.$message.error("请输入手机号验证码");
      } else if (this.ruleForm.iDCard == "") {
        this.$message.error("请输入身份证号");
      } else if (this.ruleForm.username == "") {
        this.$message.error("请输入昵称");  
      } 
      else if (this.ruleForm.lastname == "") {
        this.$message.error("请输入姓名"); 
      }else if (this.ruleForm.password == "") {
        this.$message.error("请输入密码");
      } else if (this.ruleForm.confirmPwd == "") {
        this.$message.error("请输入再次输入密码");
      } else {
        API.register({
         rdName:this.ruleForm.lastname,
         rdPasswd: this.ruleForm.password,
         rdid: this.ruleForm.iDCard,
          birthday: this.ruleForm.value1,
          confirmPwd: this.ruleForm.confirmPwd,
         smsCode: this.ruleForm.numcode.replace(/\s/g, ""),
         rdLoginId: this.ruleForm.phone.replace(/\s/g, ""),



        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "成功",
              type: "success",
            });
            this.$router.push({
              path: "/accountlogin",
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.yyqx {
 width: 175px;
    height: 1px;
    background-color: #ae703e;
    margin: -1% auto;
    margin-bottom: 19px
}
.register .con{
  display: flex;
  flex-direction: column;
}
.yy {
  margin: 10px 0 0 5%;
  display: flex;
  align-items: center;
  width: 80%;
  /* align-items: center; */
  justify-content: space-around;
}
::v-deep .el-form-item {
  width: 75%;
  margin-bottom: 0 !important;
}
 el-form-item{
  width: 100% !important;
}
.phonenum {
 display: block;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 44px;
    letter-spacing: 0px;
    color: #333333 !important;
    width: 25%;
    margin-right: 20px;
    white-space: nowrap;
    text-align: right;
}

::v-deep .el-input__inner {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  font-size: 15px;
}
.title {
  font-family: SourceHanSansCN-Medium;
  font-size: 30px !important;
  color: #ae703e;
}
.j-center {
 margin-left: 9%;
  display: flex;
  justify-content: center;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner{
  width: 100% !important;
}
.register {
  width: 100%;
  height: 100vh;
  background-image: url(../images/zzsr77.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.register .middle {
  margin: 0 auto;
  width: 40%;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0px 2px 27px 2px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  opacity: 0.9;
  position: fixed;
  top: 20vh;
  left: 30%;
}
.register .middle .con {
  width: calc(100% -20px);
  height: auto;
  padding: 20px;
}
.register .middle .con .title {
  /* text-align: center;
  color: #ae703e;
  font-size: 22px !important;
  letter-spacing: 22px;
  margin-left: 22px;
  margin-top: -1%; */
  width: 175px;
    height: 37px;
    text-align: center;
    margin: 17px auto;
    color: #ae703e;
    font-size: 22px !important;
    letter-spacing: 22px;
    border-bottom: 1px solid;
}
.register .el-step__icon.is-text {
  background-color: #e94f35;
}
.register .middle .con .step {
  width: 80%;
  display: flex;
  margin: 0 auto;
}
.register .middle .con .step span {
  width: 50px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ccc;
  border: 50%;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.register .middle .con .step .active {
  background-color: #e94f35;
}
.register .middle .con .step em {
  display: inline-block;
  width: calc(80% - 105px);
  line-height: 35px;
  height: 1px;
  background-color: #ccc;
  margin-top: 16px;
}
.register .middle .con .getcode {
  display: inline-block;
  width: 95px;
  height: 99%;
  font-size: 10px;
  position: absolute;
  right: 2px;

  text-align: center;
  border: none;
  border-radius: 5px;
  color: #f7f5f5;
  outline: none;
  background-color: #e26f12;
}
.register .middle .con .getcode11 {
  display: inline-block;
  width: 95px;
  height: 99%;
  font-size: 10px;
  position: absolute;
  right: 2px;
  /* top: 2px; */
  text-align: center;
  border: none;
  background-color: #e94f35;
  color: #ffffff;
  outline: none;
}
.content {
  display: flex;
  justify-content: center;
}
.el-form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
}
.register .middle .con .getcode11::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 21px;
  background-color: #ccc;
  position: absolute;
  top: 10px;
  left: 0px;
}
.register .middle .con .getcode img,
.register .middle .con .getcode11 img {
  width: 100%;
  height: 100%;
}
.register .middle .con .next {
  width: 65%;
  height: 40px;
  background-color: #ae703e;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  margin-top: 3%;
}
.register .middle .con .bottom {
  text-align: right;
  font-size: 12px;
  margin-top: 21px;
  margin-bottom: 28px;
  color: #666;
  width: 77%;
}
.register .middle .con .bottom span {
  color: #2882fe;
  letter-spacing: 2px;
}
</style>
